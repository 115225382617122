import { RawIntlProvider } from 'react-intl';
import type { PropsWithChildren } from 'react';
import { ConfigProvider } from '@pledge-earth/web-components';
import { I18nProvider } from '@pledge-earth/product-language';

import { useAppSelector } from './store/hooks';
import { english } from './locales/en-GB';
import { french } from './locales/fr-FR';
import { getIntl } from './locales/intl';

const locales = {
  'en-GB': english,
  'fr-FR': french,
};

export function Localization({ children }: PropsWithChildren<unknown>) {
  const locale = useAppSelector((state) => state.settings.locale);
  const currentLocale = locales[locale];
  const intl = getIntl({ locale: currentLocale.locale, messages: currentLocale.messages });
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <RawIntlProvider value={intl}>
        <I18nProvider locale={currentLocale.locale}>{children}</I18nProvider>
      </RawIntlProvider>
    </ConfigProvider>
  );
}
