import { useCallback, useSyncExternalStore } from 'react';

/**
 * The useMediaQuery hook leverages the window.matchMedia API to subscribe to CSS media query changes, thereby providing
 * real-time responsiveness to dynamic changes in the viewport or screen orientation.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @see https://github.com/uidotdev/usehooks/blob/f2794aa6799b129c77ddc914d85e5222d8488796/index.js#L760
 *
 * @example
 * const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
 * const isMediumDevice = useMediaQuery(
 *   "only screen and (min-width : 769px) and (max-width : 992px)"
 * );
 * const isLargeDevice = useMediaQuery(
 *   "only screen and (min-width : 993px) and (max-width : 1200px)"
 * );
 * const isExtraLargeDevice = useMediaQuery(
 *   "only screen and (min-width : 1201px)"
 * );
 */
export function useMediaQuery(query: string): boolean {
  const subscribe = useCallback(
    (callback: () => void) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener('change', callback);
      return () => {
        matchMedia.removeEventListener('change', callback);
      };
    },
    [query],
  );

  const getSnapshot = () => window.matchMedia(query).matches;

  const getServerSnapshot = () => {
    throw Error('useMediaQuery is a client-only hook');
  };

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}
