import { useIntl } from 'react-intl';

import { Image } from '../Image/Image';

interface Props {
  imageProps?: any;
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export function Loading(props: any & Props) {
  const { formatMessage } = useIntl();
  return (
    <div className="flex justify-center" {...props}>
      <Image
        src="/images/PledgeLoading.gif"
        alt={formatMessage({ id: 'loading' })}
        className="h-[57.6px] w-[72px]"
        hideFallback={true}
        {...(props.imageProps || {})}
      />
    </div>
  );
}
