import { useIntl } from 'react-intl';
// eslint-disable-next-line no-restricted-imports -- Drawer will be replaced at the same time as layout
import { Drawer } from '@pledge-earth/web-components';
import { IconButton, MenuCollapseIcon, MenuExpandIcon } from '@pledge-earth/product-language';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { settingChanged } from '../../store/settings/reducers';

import { MenuLeftContainer as ConnectedMenuLeft } from './MenuLeft';
import './MenuLeft.scss';

export function Menu() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const isMobileMenuOpen = useAppSelector((state) => state.settings.isMobileMenuOpen);
  const isMobileView = useAppSelector((state) => state.settings.isMobileView);
  const leftMenuWidth = useAppSelector((state) => state.settings.leftMenuWidth);

  const toggleMobileMenu = () => {
    dispatch(
      settingChanged({
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      }),
    );
  };

  function GetMenu() {
    if (isMobileView) {
      return (
        <div>
          <IconButton
            label={isMobileMenuOpen ? formatMessage({ id: 'collapse' }) : formatMessage({ id: 'expand' })}
            variant="subtle"
            className="MenuLeft__mobile-menu--toggler"
            onPress={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <MenuCollapseIcon /> : <MenuExpandIcon />}
          </IconButton>
          <Drawer
            closable={false}
            open={isMobileMenuOpen}
            placement="left"
            className="MenuLeft__mobile-menu"
            onClose={toggleMobileMenu}
            maskClosable={true}
            getContainer={undefined}
            width={leftMenuWidth}
          >
            <ConnectedMenuLeft />
          </Drawer>
        </div>
      );
    }

    return <ConnectedMenuLeft />;
  }

  return GetMenu();
}
