import { all, takeEvery, put } from 'redux-saga/effects';
import store from 'store';
import qs from 'qs';

import { store as reduxStore } from '../store';
import { LanguageEnum } from '../../services/graphql/generated';

import { localeChanged, settingChanged } from './reducers';

function* changeSetting({ payload: { setting, value } }: ReturnType<typeof settingChanged>) {
  yield store.set(`app.settings.${setting}`, value);
}

function* updateLocale({ payload: { language } }: Pick<ReturnType<typeof localeChanged>, 'payload'>) {
  let locale = 'en-GB';

  if (language) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- eslint upgrade
    if (language === LanguageEnum.FrFr) {
      locale = 'fr-FR';
    }
  } else {
    // Auto detect language
    const navigatorLanguage =
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain -- eslint upgrade
      navigator.language || (navigator.languages && navigator.languages[0]) || (navigator as any).userLanguage;
    if (navigatorLanguage.startsWith('fr')) {
      locale = 'fr-FR';
    }
  }

  // Since we don't have french translations for every key
  // we force the english locale
  locale = 'en-GB';

  yield put(
    settingChanged({
      setting: 'locale',
      value: locale,
    }),
  );
}

function* setup() {
  // load settings from url on app load
  const changeSettings = (search: string) => {
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    Object.keys(query).forEach((key) => {
      let value: any;
      switch (query[key]) {
        case 'false':
          value = false;
          break;
        case 'true':
          value = true;
          break;
        default:
          value = query[key];
          break;
      }
      reduxStore.dispatch(
        settingChanged({
          setting: key as any,
          value,
        }),
      );
    });
  };
  yield changeSettings(window.location.search);

  const mobileScreenWidthMax = 991;

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const shouldBeMobileView = global.window.innerWidth <= mobileScreenWidthMax;
    const isCurrentlyMobileView = store.get('app.settings.isMobileView');
    if (shouldBeMobileView !== isCurrentlyMobileView || load) {
      reduxStore.dispatch(
        settingChanged({
          setting: 'isMobileView',
          value: shouldBeMobileView,
        }),
      );
    }
  };

  // detect viewport width on app load and window resize
  const isMenuCollapsed = () => {
    const shouldBeCollapsed = global.window.innerWidth <= mobileScreenWidthMax;
    const isCurrentlyCollapsed = store.get('app.settings.isMenuCollapsed');
    if (shouldBeCollapsed && !isCurrentlyCollapsed) {
      reduxStore.dispatch(
        settingChanged({
          setting: 'isMenuCollapsed',
          value: true,
        }),
      );
    }
  };

  yield isMobileView(true);
  yield isMenuCollapsed();
  yield window.addEventListener('resize', () => {
    isMobileView();
    isMenuCollapsed();
  });

  // detect Language
  yield updateLocale({ payload: { language: null } });
}

export function* sagas() {
  yield all([
    takeEvery(settingChanged, changeSetting),
    takeEvery(localeChanged, updateLocale),

    setup(), // run once on app load to init listeners
  ]);
}
