import { FormattedMessage } from 'react-intl';
import { Text } from '@pledge-earth/product-language';
import classNames from 'classnames';

import { useAppSelector } from '../../store/hooks';
import type { MessageId } from '../../locales/types';

export function Subheader({ message }: { message: MessageId }) {
  const isMobileView = useAppSelector((state) => state.settings.isMobileView);
  const isMobileMenuOpen = useAppSelector((state) => state.settings.isMobileMenuOpen);
  const isMenuCollapsed = useAppSelector((state) => state.settings.isMenuCollapsed);
  // Hide the subheader from view when:
  // - it is not mobile view and the menu is collapsed
  // - it is mobile view and the mobile menu is closed
  const hidden = (!isMobileView && isMenuCollapsed) || (isMobileView && !isMobileMenuOpen);
  return (
    <Text
      className={classNames('MenuLeft__subheader', {
        'MenuLeft__subheader--hidden': hidden,
      })}
    >
      <FormattedMessage id={message} />
    </Text>
  );
}
