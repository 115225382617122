import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Switch, twMerge, Size } from '@pledge-earth/product-language';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';

import { useAppSelector } from '../../../store/hooks';
import '../MenuLeft.scss';
import { useIsTestMode } from '../../../hooks/useIsTestMode';
import { useNavigateWithTestMode } from '../../../hooks/useNavigateWithTestMode';

export function TestModeSwitch() {
  const isMobileView = useAppSelector((state) => state.settings.isMobileView);
  const isMenuCollapsed = useAppSelector((state) => state.settings.isMenuCollapsed);
  const isTestMode = useIsTestMode();
  const { pathname } = useLocation();
  const navigateWithTestMode = useNavigateWithTestMode();

  const switchMode = useCallback(() => {
    navigateWithTestMode(pathname, { testMode: !isTestMode });
  }, [isTestMode, navigateWithTestMode, pathname]);

  return (
    <div
      className={classNames(
        'mx-3 -mt-[0.125rem] mb-[1.5rem] flex h-8 min-h-[1.5rem] items-center overflow-hidden rounded pl-[0.65rem] transition-none',
        isTestMode && 'bg-warning-subdued',
      )}
      data-cy="test-mode-switch-container"
    >
      <Switch
        variant="warning"
        isSelected={isTestMode}
        size={Size.Compact}
        onChange={switchMode}
        data-cy="test-mode-switch-switch"
        className="gap-x-[0.4rem]"
      >
        <span
          className={twMerge(
            '-mt-[0.313rem] mr-1 whitespace-nowrap font-medium opacity-100 transition-opacity duration-200 ease-out',
            isMenuCollapsed && ['pointer-events-none opacity-0', isMobileView && 'mt-0 inline-block opacity-100'],
          )}
        >
          <FormattedMessage id="leftMenu.mode" />
        </span>
      </Switch>
    </div>
  );
}
