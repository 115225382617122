import { RouterProvider } from '@pledge-earth/product-language';
import type { NavigateOptions } from 'react-router-dom';

import { useHrefWithTestMode } from '../../hooks/useHrefWithTestMode';
import { useNavigateWithTestMode } from '../../hooks/useNavigateWithTestMode';

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: NavigateOptions;
  }
}

export function RACRouterProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigateWithTestMode();

  return (
    <RouterProvider navigate={navigate} useHref={useHrefWithTestMode}>
      {children}
    </RouterProvider>
  );
}
