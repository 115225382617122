import classNames from 'classnames';

import { Loading } from './Loading';
import './LoadingFullScreen.scss';

interface Props {
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- eslint upgrade
export function LoadingFullScreen(props: any & Props) {
  return (
    <div className={classNames('LoadingFullScreen', props.className)} {...(props || {})}>
      <Loading className="LoadingFullScreen__icon" />
    </div>
  );
}
